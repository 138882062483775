import React, { useState } from 'react';
import './LandingPage.css';
import ReactGA from 'react-ga4';

function LandingPage() {
  const [email, setEmail] = useState('');
  const [submitted, setSubmitted] = useState(false);
  const [emailError, setEmailError] = useState('');
  const [carouselIndex, setCarouselIndex] = useState(0);

  ReactGA.send({
    hitType: 'pageview',
    page: "/",
    title: "landing"
  });

  const validateEmail = (email) => {
    const re = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return re.test(String(email).toLowerCase());
  }

  const handleSubmit = (e) => {
    e.preventDefault();
    if (!validateEmail(email)) {
      setEmailError('Please enter a valid email address.');
      return;
    }

    setEmailError('');
    const webhookUrl = 'https://lets-innovate.embeddingstud.io/subscribe'; // URL of your email webhook server
    const payload = { email };

    setSubmitted(true);
    fetch(webhookUrl, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(payload)
    })
      .then(response => response.json())
      .then(data => {
        console.log('Success:', data);
      })
      .catch((error) => {
        console.error('Error:', error);
      });
  };

  const nextSlide = () => {
    setCarouselIndex((carouselIndex + 1) % 2);
  };

  const prevSlide = () => {
    setCarouselIndex((carouselIndex - 1 + 2) % 2);
  };

  return (
    <div className="container">
      <div className="left-column">
        <img src="logo.png" alt="Let's Innovate Logo" className="logo" />
        <h1>Struggling to bring your SOTA AI project to life due to limited GPU and data resources?</h1>
        <h2>Stop spending your own $15,000,00+ for 8 NVIDIA A100 GPUs, AI community needs your models, too.</h2>
        <div className="markdown-section">
          <pre>{`
| Model         | Training Cost (USD)  | Usage (hours) |
|---------------|----------------------|---------------|
| LLaMA         | $1,500,000           | 10,000        |
| BERT          | $7,000               | 5,000         |
| RoBERTa       | $60,000              | 5,000         |
| DistilBERT    | $40,000              | 3,500         |
| ALBERT        | $100,000             | 6,000         |
          `}</pre>
        </div>
        <form id="subscribe-form" className="subscribe-form" onSubmit={handleSubmit}>
          <h3>Launch in September! Sign up to get early access:</h3>
          <input
            type="email"
            placeholder="Enter your email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            required
          />
          <button type="submit">Get Access</button>
          {emailError && <p className="email-error">{emailError}</p>}
          {submitted && <h3 className="thank-you">Thank you for signing up and Check your email!</h3>}
          {!submitted && <h3 className="thank-you">Bring Your AI Vision to Life!</h3>}
        </form>
        <h2 className="high-lighted">Share your vision on Let's Innovate and get the funding and computational power you need from our community of AI enthusiasts and investors.</h2>
      </div>
      <div className="right-column">
        <div className="problem-solution-container">
          <div className="problem">
            <h3>💸 Lack of Funding</h3>
            <p>Training and experimenting with ML models can be costly. Don't let limited resources hold you back.</p>
          </div>
          <div className="solution">
            <h3>🚀 Crowdfund Your AI Project</h3>
            <p>Share your costs of accessing world best data annotation platforms and services.</p>
          </div>
        </div>
        <div className="problem-solution-container">
          <div className="problem">
            <h3>🧩 Need for Collaboration</h3>
            <p>AI innovation thrives on collaboration. Connect with experts and enthusiasts to refine your ideas and approach.</p>
          </div>
          <div className="solution">
            <h3>🧠 Collaborate with the Community</h3>
            <p>Streamline your data collection process by organizing your team on our platform.</p>
          </div>
        </div>
        <div className="problem-solution-container">
          <div className="problem">
            <h3>🖥️ Limited Access to GPU Power</h3>
            <p>Training complex models requires significant computational resources. Secure the GPU power you need.</p>
          </div>
          <div className="solution">
            <h3>💸 Maximize Your AI Budget</h3>
            <p>Split the cost of expensive GPU instances and data annotation services with other researchers and developers.</p>
          </div>
        </div>
        <div className="problem-solution-container">
          <div className="problem">
            <h3>📂 Data Annotation Difficulties</h3>
            <p>High-quality annotated data is essential for model performance. Access reliable data annotation services.</p>
          </div>
          <div className="solution">
            <h3>📝 Access Reliable Data Annotation Services</h3>
            <p>Utilize our platform to get high-quality annotated data for better model performance.</p>
          </div>
        </div>
      </div>
    </div>
  );
}

export default LandingPage;
