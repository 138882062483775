import React, { useEffect } from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import LandingPage from './components/LandingPage/LandingPage';
import Footer from './components/Footer/Footer';
import ReactGA from 'react-ga4';
import './App.css';

const App = () => {
  ReactGA.initialize('G-9CJ7KGX34N');

  return (
    <Router>
      <div className="app-container">
        <Switch>
          <Route path="/" exact component={LandingPage} />
          {/* Add other routes here */}
        </Switch>
        <Footer />
      </div>
    </Router>
  );
};

export default App;
